import React from "react";
import Head from "next/head";
import Header from "../components/Header";
import Page from "../components/Page";
import Footer from "../components/Footer";
import CommonHeadTags from "../components/CommonHeadTags";
import {
  GoogleAnalyticsHead,
  GoogleAnalyticsBody,
} from "../components/GoogleAnalytics";
import withLocale from "../translations/withLocale";
import useTranslation from "../translations/useTranslation";
import { nonEnglishLocales } from "../translations/config";

const Home = () => {
  const { t } = useTranslation();

  return (
    <>
      <Head>
        <title>{t("title")}</title>
        <meta name="description" content={t("description")} />
        <meta
          name="google-site-verification"
          content="HWz8XojaBV0-rNOnuBnZzJJRSQfQFuXV80-qCAZRsG8"
        />

        <meta httpEquiv="content-language" content="en" />
        <link rel="canonical" href="https://guitar-tuner.org" />
        <link
          rel="alternate"
          hrefLang="x-default"
          href="https://guitar-tuner.org"
        />
        <link rel="alternate" hrefLang="en" href="https://guitar-tuner.org" />
        {nonEnglishLocales.map((locale) => (
          <link
            key={locale}
            rel="alternate"
            hrefLang={locale}
            href={`https://guitar-tuner.org/${locale}`}
          />
        ))}
        <CommonHeadTags />
        <GoogleAnalyticsHead />
      </Head>

      <Header hideStickyBar />

      <main>
        <Page pageData="home" />
      </main>

      <Footer />
      <GoogleAnalyticsBody />
    </>
  );
};

export default withLocale(Home);
